import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Button, Icon, TextareaHelper, Label, Typography, AlertBar } from '@packages/ui/shared';
import { useAuthState } from '@packages/contexts/auth';
import colors from '@packages/core/styles/colors';
import {
    TechlineHeader,
    TechlineSections,
    TechlineSideNav,
    Comments,
    VehicleInfo,
    CaseDetails,
    FailCode,
    Attachments,
    DtcsDetails,
} from '../components';
import { createUseStyles } from 'react-jss';
import { Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { techlineService, analyticsService } from '@web/services/singletons';
import { format } from 'date-fns';
import { caseStatusMap as statusMap } from '@web/utils';
import { Resolution } from '../components/resolution';
import { Form } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { AssetType, TechlineStatusCode } from '@packages/models/api';
import { compareDesc } from 'date-fns';
import { WorksheetReport, WorksheetValid } from '../components/worksheet/worksheet-report';
import { Retailer } from '@packages/models/api/techline';
import useUserDetailsOrPermissions from '../components/worksheet/hooks/useUserDetailsOrPermissions';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';
import CaseTeam from '../components/case-team';

const useStyles = createUseStyles({
    container: {
        height: '100%',
    },
    centerContainer: {
        flex: 1,
        overflow: 'scroll',
        borderLeft: `1px solid ${colors.grayTwo}`,
        borderRight: `1px solid ${colors.grayTwo}`,
    },
    rightContainer: {
        backgroundColor: colors.grayOne,
        width: '400px',
        height: '100%',
        overflow: 'scroll',
    },
    rightContainerFullScreen: {
        backgroundColor: colors.grayOne,
        flex: 1,
        overflowY: 'scroll',
    },
    rightContainerHead: {
        height: '56px',
    },
    flexOne: {
        flex: 1,
    },
    attachmentsSection: {
        width: '400px',
        borderLeft: `1px solid ${colors.grayFour}`,
    },
    cursorPointer: {
        cursor: 'pointer',
    },
});

export const Open = ({
    caseNumber,
    vehicleDetails,
    caseDetails,
    assetsDetails,
    fetchAssets,
    fetchCaseDetails,
}: {
    caseNumber: any;
    vehicleDetails: any;
    caseDetails: Retailer | undefined;
    assetsDetails: any;
    fetchAssets: any;
    fetchCaseDetails: (caseNumber: string) => Promise<void>;
}) => {
    const classes = useStyles();
    const { account } = useAuthState();
    const [currentSelectedSection, setCurrentSelectedSection] = useState<TechlineSections>(
        TechlineSections.VehicleInformation
    );
    const navigate = useNavigate();

    const [isCommentsExpanded, setIsCommentsExpanded] = useState(false);
    const [isCommentLoading, setIsCommentLoading] = useState(false);
    const [showCloseCaseModal, setShowCloseCaseModal] = useState(false);
    const [closeCaseLoading, setCloseCaseLoading] = useState(false);
    const [closeDescription, setCloseDescription] = useState('');
    const [currentEditingComment, setCurrentEditingComment] = useState('');
    const [comments, setComments] = useState([]);
    const [rightContainerCurrentOpenedTab, setRightContainerCurrentOpenedTab] = useState('comments');
    const [isVehicleRepaired, setIsVehicleRepaired] = useState(true);
    const [detailsEditedMap, setDetailsEditedMap] = useState({
        vehicleInformation: true,
        caseDetails: true,
        DTCs: true,
        failCode: true,
        worksheets: true,
        attachments: true,
        resolution: true,
    });
    const [isWorksheetsValid, setIsWorksheetsValid] = useState<WorksheetValid>({
        submitted: false,
        draft: false,
        caseSelected: false,
    });
    const [errorMessage, setErrorMessage] = useState('');

    const handleIsWorksheetValid = useCallback((newState: Partial<WorksheetValid>) => {
        console.log('check valid ==> ', newState);
        setIsWorksheetsValid((prev) => ({ ...prev, ...newState }));
    }, []);
    useEffect(() => {
        if (caseNumber) {
            fetchComments(caseNumber);
        }
    }, [caseNumber]);

    const isCurrentWorksheetsValid = useMemo(() => {
        const message: string[] = [];
        if (!(isWorksheetsValid.submitted || isWorksheetsValid.draft)) {
            message.push('At least one worksheet is required for every case type');
        }
        if (!isWorksheetsValid.caseSelected) {
            message.push('Case type');
        }
        return message.length;
    }, [isWorksheetsValid]);

    const onDrop = useCallback(
        async (acceptedFiles: any) => {
            try {
                if (acceptedFiles[0]) {
                    analyticsService.logEvent(ANALYTICS_EVENTS.USER_STARTS_ATTACHMENT_COMMENT);
                    scrollToSection(TechlineSections.Attachments);
                    const assetsPreReqBody = {
                        assets: [
                            {
                                assetTypeId: acceptedFiles[0].type.includes('video')
                                    ? AssetType.Video
                                    : acceptedFiles[0].type.includes('image')
                                      ? AssetType.Image
                                      : AssetType.File,
                                assetDispositionId: 'TechShare',
                                caseNumber,
                                name: acceptedFiles[0].name,
                                fileName: acceptedFiles[0].name,
                                restrdicted: false,
                                note: '',
                                hasOverlay: false,
                            },
                        ],
                        techlinecasestatus:
                            caseDetails?.techlineStatusCode === TechlineStatusCode.OPEN_ESCALATED
                                ? TechlineStatusCode.OPEN_ESCALATED
                                : TechlineStatusCode.PENDING_TECHLINE,
                    };
                    const postAssetsRes = await techlineService.postAssets({ assetsBody: assetsPreReqBody });
                    const assetUploadReqParams = postAssetsRes?.data?.results?.assets?.[0]?.presignedUpload;
                    if (assetUploadReqParams) {
                        await techlineService.uploadAssets({
                            uploadUrl: assetUploadReqParams.url,
                            headers: assetUploadReqParams.httpHeaders,
                            assetsBody: acceptedFiles[0],
                        });
                        analyticsService.logEvent(ANALYTICS_EVENTS.USER_SUCCESSFULLY_UPLOADS_ATTACHMENT_COMMENT);
                        fetchAssets(caseNumber);
                    }
                }
            } catch (err) {
                alert('Error: ' + err + '. Please refresh & try again.');
                console.log(err);
            }
        },
        [caseNumber, fetchAssets]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const fetchComments = async (caseNumber: string) => {
        try {
            const commentsRes: any = await techlineService.getComments({ caseNumber });
            if (commentsRes?.data?.results?.length) {
                const currentComments = commentsRes.data.results;
                setComments(
                    currentComments.sort((a: { createdOn: any }, b: { createdOn: any }) => {
                        return compareDesc(new Date(b.createdOn), new Date(a.createdOn));
                    })
                );
            }
        } catch (err) {
            alert('Error: ' + err + '. Please refresh & try again.');
            console.log(err);
        }
    };

    const onCloseCase = async () => {
        try {
            setCloseCaseLoading(true);
            const updateCaseDetailsRes = await techlineService.updateCaseStatus({
                updateBody: {
                    casenumber: caseNumber,
                    username: account?.soaUsername || '',
                    retailernumber: caseDetails?.retailerProfiles?.find(
                        (retailer: { isPrimary: any }) => retailer.isPrimary
                    )?.retailerNumber,
                    techlinecasestatus: 'CLOSED-RESOLVED',
                    techsharecaseclosuredescription: closeDescription,
                    closuredate: format(new Date(), 'yyyy-MM-dd'),
                    wasTheVehicleRepaired: isVehicleRepaired,
                },
            });
            setShowCloseCaseModal(false);
            setCloseCaseLoading(false);
            if (updateCaseDetailsRes?.success) {
                analyticsService.logEvent(ANALYTICS_EVENTS.USER_SUCCESSFULLY_CLOSES_TECHLINE_CASE);
                navigate(
                    `/techline/dashboard?showCloseCaseSuccessFor=${caseNumber}&&retailerNumber=${
                        caseDetails?.retailerProfiles?.find((retailer: { isPrimary: any }) => retailer.isPrimary)
                            ?.retailerNumber
                    }`
                );
            }
        } catch (err) {
            console.log(err);
            alert('Error: ' + err + '. Please refresh & try again.');
            setCloseCaseLoading(false);
        }
    };

    const updateCaseStatusToPending = async () => {
        try {
            const updateCaseDetailsRes = await techlineService.updateCaseStatus({
                updateBody: {
                    casenumber: caseNumber,
                    username: account?.soaUsername || '',
                    retailernumber: caseDetails?.retailerProfiles?.find(
                        (retailer: { isPrimary: any }) => retailer.isPrimary
                    )?.retailerNumber,
                    techlinecasestatus: 'PENDING-TECHLINE',
                },
            });
            if (updateCaseDetailsRes?.success) {
                fetchCaseDetails(caseNumber || '');
            }
        } catch (err) {
            alert('Error: ' + err + '. Please refresh & try again.');
            console.log(err);
        }
    };

    const scrollToSection = (section: TechlineSections) => {
        setCurrentSelectedSection(section);
        const targetSection = document.querySelector(`#${TechlineSections[section]}`);
        targetSection?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const onCenterContainerScrolled = () => {
        const sections: any = Object.values(TechlineSections);
        let selectedSectionId: any = TechlineSections.VehicleInformation;
        for (let i = 0; i < sections.length; i++) {
            if (typeof sections[i] !== 'string') {
                continue;
            }
            const currentSection: any = document.querySelector(`#${sections[i]}`);
            if (currentSection) {
                const topSpace = currentSection.getBoundingClientRect().top;
                const height = currentSection.getBoundingClientRect().height;
                if (topSpace > 0 || height - 200 + topSpace > 0) {
                    selectedSectionId = sections[i];
                    break;
                }
            }
        }
        setCurrentSelectedSection(TechlineSections[selectedSectionId] as any);
    };

    const expandComments = () => {
        setIsCommentsExpanded((preVal) => !preVal);
        setCurrentSelectedSection(TechlineSections.VehicleInformation);
    };

    const saveComment = async () => {
        if (!currentEditingComment) return;
        setIsCommentLoading(true);
        analyticsService.logEvent(ANALYTICS_EVENTS.USER_ADDS_COMMENT);
        try {
            const addCommentBody = {
                casenumber: caseNumber,
                // If it is in OPEN-ESCALATED, do NOT update case status.
                // Per status change diagram (TECHSHARE-1141), OPEN-ESCALATED status should not be changed upon edit.
                // Anything else, we will make it PENDING TECHLINE.
                techlinecasestatus:
                    caseDetails?.techlineStatusCode === TechlineStatusCode.OPEN_ESCALATED
                        ? 'OPEN-ESCALATED'
                        : 'PENDING TECHLINE',
                username: account?.soaUsername || '',
                retailernumber: caseDetails?.retailerProfiles?.find(
                    (retailer: { isPrimary: any }) => retailer.isPrimary
                )?.retailerNumber,
                message: currentEditingComment,
            };
            const addCommentReq = await techlineService.addComment({ body: addCommentBody });
            if (addCommentReq?.success) {
                fetchComments(caseNumber || '');
                setCurrentEditingComment('');
                // If it is in OPEN-ESCALATED, do NOT update case status.
                // Per status change diagram (TECHSHARE-1141), OPEN-ESCALATED status should not be changed upon edit.
                if (caseDetails?.techlineStatusCode !== TechlineStatusCode.OPEN_ESCALATED) {
                    updateCaseStatusToPending();
                }
            }
            setIsCommentLoading(false);
        } catch (err) {
            alert('Error: ' + err + '. Please refresh & try again.');
            console.log(err);
            setIsCommentLoading(false);
        }
    };

    const rightContainerHead = () => {
        return (
            <div className={`${classes.rightContainerHead} d-flex flex-row align-items-center justify-content-between`}>
                <div className="d-flex flex-row align-items-center">
                    <div
                        onClick={() => setRightContainerCurrentOpenedTab('comments')}
                        className={`ml-4 mt-3 d-flex flex-column ${classes.cursorPointer}`}
                    >
                        <Typography
                            color={rightContainerCurrentOpenedTab === 'comments' ? 'blueOne' : 'grayFive'}
                            variant="h6"
                        >
                            Comments
                        </Typography>
                        <div
                            className="mt-3"
                            style={{
                                borderBottom: `2px solid ${
                                    rightContainerCurrentOpenedTab === 'comments' ? colors.blueOne : colors.grayOne
                                }`,
                            }}
                        />
                    </div>
                    <div
                        onClick={() => setRightContainerCurrentOpenedTab('details')}
                        className={`ml-4 mt-3 d-flex flex-column ${classes.cursorPointer}`}
                    >
                        <Typography
                            color={rightContainerCurrentOpenedTab === 'details' ? 'blueOne' : 'grayFive'}
                            variant="h6"
                        >
                            Details
                        </Typography>
                        <div
                            className="mt-3"
                            style={{
                                borderBottom: `2px solid ${
                                    rightContainerCurrentOpenedTab === 'details' ? colors.blueOne : colors.grayOne
                                }`,
                            }}
                        />
                    </div>
                    {/* <div className="ml-4 mt-3 d-flex flex-column">
                        <Typography color="grayFive" variant="h6">
                            Activity
                        </Typography>
                        <div
                            className="mt-3"
                            style={{
                                borderBottom: `2px solid ${colors.grayOne}`,
                            }}
                        />
                    </div> */}
                </div>
                <div className="d-flex align-items-center mr-5">
                    <Button onPress={expandComments} variant="link">
                        <Icon size={24} name={isCommentsExpanded ? 'minus' : 'plus'} />
                    </Button>
                </div>
            </div>
        );
    };

    const renderDetailsTab = () => {
        return (
            <div className="ml-4 mt-3 mr-4">
                <div className="d-flex flex-row justify-content-between">
                    <Typography color="black" variant="h5">
                        Retailer
                    </Typography>
                </div>
                {caseDetails?.retailerUniqueProfiles?.length &&
                    caseDetails.retailerUniqueProfiles.map((retailer: any) => (
                        <div className="mt-3 d-flex flex-column">
                            <Typography color="blueOne">{retailer.name}</Typography>
                            <div className="mt-1">
                                <Typography>
                                    {retailer.city} {retailer.regionCode}, {retailer.state} {retailer.zoneCode},{' '}
                                    {retailer.districtCode}
                                </Typography>
                            </div>
                        </div>
                    ))}
                <div
                    className="mt-5"
                    style={{
                        borderBottom: `1px solid ${colors.grayThree}`,
                    }}
                />

                <CaseTeam
                    retailerNumber={
                        caseDetails?.retailerProfiles?.find((retailer: { isPrimary: boolean }) => retailer.isPrimary)
                            ?.retailerNumber || ''
                    }
                    retailerProfiles={caseDetails?.retailerProfiles || []}
                    caseNumber={caseNumber}
                    showError={onShowErrorMessage}
                />

                <div
                    className="mt-5"
                    style={{
                        borderBottom: `1px solid ${colors.grayThree}`,
                    }}
                />

                <div className="mt-3 d-flex flex-column">
                    <Typography color="black" variant="h5">
                        Case Details
                    </Typography>

                    <div className="mt-3 d-flex flex-row align-items-center">
                        <div className="d-flex flex-column">
                            <Typography variant="caption">
                                <b>Last Updated:</b>{' '}
                                {caseDetails?.modifiedOnDescription ? caseDetails.modifiedOnDescription : 'Today'}
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    const onShowErrorMessage = useCallback((error: string) => {
        setErrorMessage(error);
        setTimeout(() => {
            setErrorMessage('');
        }, 5000);
    }, []);
    const {
        permissions: { canAddComment, canAddOrEditAttachment },
    } = useUserDetailsOrPermissions();
    const renderRightContainer = () => {
        return (
            <div className={`${classes.rightContainer} d-flex flex-column justify-content-between`}>
                <div>
                    {rightContainerHead()}
                    <hr color={colors.grayThree} />
                    {/* <div className="d-flex flex-row align-items-center ml-3 mt-3 mb-3">
                        <Icon size={24} name="magnifying-glass" />
                        <TextInput style={styles.textInput} placeholder="Search" />
                    </div>
                    <hr color={colors.grayThree} /> */}
                    {rightContainerCurrentOpenedTab === 'comments' && (
                        <div className="mt-5">
                            <Comments currentUserName={account?.soaUsername} comments={comments} />
                        </div>
                    )}
                    {rightContainerCurrentOpenedTab === 'details' && renderDetailsTab()}
                </div>
                {!statusMap['CLOSED'].includes(caseDetails?.techlineStatusCode) &&
                    rightContainerCurrentOpenedTab === 'comments' &&
                    canAddComment && (
                        <div className="ml-3 mr-3">
                            <div
                                className="mb-2 mt-2"
                                style={{
                                    borderTop: `1px solid ${colors.grayThree}`,
                                }}
                            />
                            <Typography>
                                Comments submitted through TechShare will be entered into our queue and responded in the
                                order it is received.
                            </Typography>
                            <div className="mt-3 bg-white">
                                {canAddOrEditAttachment && (
                                    <div
                                        {...getRootProps()}
                                        style={{ marginBottom: -32, marginRight: 7 }}
                                        className="d-flex justify-content-end mb--10"
                                    >
                                        <input {...getInputProps()} />
                                        <div style={{ cursor: 'pointer', zIndex: 10, transform: 'rotate(90deg)' }}>
                                            <Icon size={24} name="paperclip" />
                                        </div>
                                    </div>
                                )}
                                <TextareaHelper
                                    containerStyle={{ height: 80 }}
                                    placeholder={'Add a comment...'}
                                    value={currentEditingComment}
                                    onChangeText={setCurrentEditingComment}
                                />
                            </div>
                            <div className="mt-2 d-flex justify-content-end">
                                {isCommentLoading ? (
                                    <Spinner animation="border" />
                                ) : (
                                    <Button variant="primary" onPress={saveComment}>
                                        Comment
                                    </Button>
                                )}
                            </div>
                        </div>
                    )}
            </div>
        );
    };

    const renderExpandedRightContainer = () => {
        return (
            <div className={`${classes.rightContainerFullScreen} d-flex flex-column`}>
                {rightContainerHead()}
                <hr color={colors.grayThree} />
                <div className={`d-flex flex-row ${classes.flexOne}`}>
                    <div className={`d-flex flex-column justify-content-between ${classes.flexOne}`}>
                        <div>
                            {/* <div className="d-flex flex-row align-items-center ml-3 mt-3 mb-3">
                                <Icon size={24} name="magnifying-glass" />
                                <TextInput style={styles.textInput} placeholder="Search" />
                            </div> */}
                            <hr color={colors.grayThree} />
                            {rightContainerCurrentOpenedTab === 'comments' && (
                                <div className="mt-5">
                                    <Comments currentUserName={account?.soaUsername} comments={comments} />
                                </div>
                            )}
                            {rightContainerCurrentOpenedTab === 'details' && renderDetailsTab()}
                        </div>
                        {!statusMap['CLOSED'].includes(caseDetails?.techlineStatusCode) &&
                            rightContainerCurrentOpenedTab === 'comments' &&
                            canAddComment && (
                                <div className="ml-3 mr-3">
                                    <div
                                        className="mb-2 mt-2"
                                        style={{
                                            borderTop: `1px solid ${colors.grayThree}`,
                                        }}
                                    />
                                    <Typography>
                                        Comments submitted through TechShare will be entered into our queue and
                                        responded in the order it is received.
                                    </Typography>
                                    <div className="mt-3 bg-white">
                                        {canAddOrEditAttachment && (
                                            <div
                                                {...getRootProps()}
                                                style={{ marginBottom: -32, marginRight: 7 }}
                                                className="d-flex justify-content-end mb--10"
                                            >
                                                <input {...getInputProps()} />
                                                <div
                                                    style={{
                                                        cursor: 'pointer',
                                                        zIndex: 10,
                                                        transform: 'rotate(90deg)',
                                                    }}
                                                >
                                                    <Icon size={24} name="paperclip" />
                                                </div>
                                            </div>
                                        )}
                                        <TextareaHelper
                                            containerStyle={{ height: 80 }}
                                            placeholder={'Add a comment...'}
                                            value={currentEditingComment}
                                            onChangeText={setCurrentEditingComment}
                                        />
                                    </div>
                                    <div className="mt-2 d-flex justify-content-end">
                                        <Button variant="primary" onPress={saveComment}>
                                            Comment
                                        </Button>
                                    </div>
                                </div>
                            )}
                    </div>
                    {/* <div className={`${classes.attachmentsSection}`}>
                        <div className="ml-2 mr-2">
                            <div className="mt-3 mb-3">
                                <Typography variant="h6" color="black">
                                    Shared photos (0)
                                </Typography>
                            </div>
                            <hr color={colors.grayThree} />
                            <div className="mt-3 mb-3">
                                <Typography variant="h6" color="black">
                                    Shared files (0)
                                </Typography>
                            </div>
                            <hr color={colors.grayThree} />
                            <div className="mt-3 mb-3">
                                <Typography variant="h6" color="black">
                                    Shared links (0)
                                </Typography>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        );
    };

    const renderCloseCaseModal = () => {
        return (
            <Modal show={showCloseCaseModal} centered={true} onHide={() => setShowCloseCaseModal(false)} size="lg">
                <Modal.Header placeholder={''}>
                    <Modal.Title>Close case</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mt-3">
                        <div className="mb-3">
                            <Label required>Was the vehicle repaired?</Label>
                        </div>
                        <Form.Check
                            className="mb-3"
                            label={'Yes'}
                            type="radio"
                            id="customer-report-yes"
                            name="customer-report"
                            checked={!!isVehicleRepaired}
                            onChange={() => {
                                setIsVehicleRepaired(true);
                            }}
                        />
                        <Form.Check
                            label={'No'}
                            type="radio"
                            id="customer-report-no"
                            name="customer-report"
                            checked={isVehicleRepaired === false}
                            onChange={() => {
                                setIsVehicleRepaired(false);
                            }}
                        />
                    </Form.Group>
                    <TextareaHelper
                        required
                        containerStyle={{ height: 80 }}
                        label={
                            isVehicleRepaired
                                ? 'Please provide a detailed explanation of your findings and final repair.'
                                : 'Please provide a detailed explanation of your findings and current status of your vehicle.'
                        }
                        value={closeDescription}
                        onChangeText={setCloseDescription}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <div className="mr-2">
                        <Button
                            variant="ghost-blue"
                            onPress={() => {
                                setShowCloseCaseModal(false);
                                setCloseDescription('');
                            }}
                        >
                            Cancel
                        </Button>
                    </div>

                    <Button
                        variant="primary"
                        isLoading={closeCaseLoading}
                        disabled={!closeDescription}
                        onPress={onCloseCase}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    return (
        <div className={`${classes.container} d-flex flex-column`}>
            <TechlineHeader
                // disablePrimaryBtn={!account?.techlineCapabilities?.canCloseTechlineCase}
                onPrimaryBtnClick={() => {
                    analyticsService.logEvent(ANALYTICS_EVENTS.USER_STARTS_CLOSE_CASE_FLOW);
                    setShowCloseCaseModal(true);
                }}
                caseNumber={caseNumber}
                caseDetails={caseDetails}
                disabledSubmit={
                    !(isWorksheetsValid.submitted || isWorksheetsValid.draft) || !isWorksheetsValid.caseSelected
                }
            />
            {errorMessage && (
                <div>
                    <AlertBar
                        error
                        show={true}
                        onClose={() => {
                            setErrorMessage('');
                        }}
                    >
                        <Typography>ERROR: {errorMessage}</Typography>
                    </AlertBar>
                </div>
            )}
            <div style={{ height: '92%' }} className="d-flex flex-row">
                <TechlineSideNav
                    caseDetails={caseDetails}
                    scrollToSection={scrollToSection}
                    currentSelectedSection={currentSelectedSection}
                    assetsDetails={assetsDetails}
                    detailsEditedMap={detailsEditedMap}
                    isCaseDetailsValid={true}
                    isFailCodeValid={true}
                    isWorksheetsValid={!isCurrentWorksheetsValid}
                />
                {!isCommentsExpanded && (
                    <div onScroll={onCenterContainerScrolled} className={`${classes.centerContainer} p-10`}>
                        <section id={TechlineSections[TechlineSections.VehicleInformation]}>
                            <VehicleInfo vehicleDetails={vehicleDetails} type={caseDetails?.techlineStatusCode || ''} />
                        </section>

                        <section id={TechlineSections[TechlineSections.CaseDetails]}>
                            {caseDetails && <CaseDetails caseDetails={caseDetails} />}
                        </section>

                        <section id={TechlineSections[TechlineSections.DTCSSymptoms]}>
                            <DtcsDetails caseDetails={caseDetails} />
                        </section>

                        <section id={TechlineSections[TechlineSections.FailCode]}>
                            <FailCode caseDetails={caseDetails} vehicleDetails={vehicleDetails} />
                        </section>

                        <section id={TechlineSections[TechlineSections.Worksheets]}>
                            <WorksheetReport
                                worksheetSubmission={caseDetails?.worksheetSubmissions}
                                caseTypesDefault={caseDetails?.caseTypes}
                                handleIsWorksheetValid={handleIsWorksheetValid}
                                caseStatus={caseDetails?.techlineStatusCode}
                            />
                        </section>

                        <section id={TechlineSections[TechlineSections.Attachments]}>
                            <Attachments
                                fetchAssets={fetchAssets}
                                assetsDetails={assetsDetails}
                                caseNumber={caseNumber || ''}
                                caseDetails={caseDetails}
                                scrollToSection={scrollToSection}
                            />
                        </section>

                        {caseDetails && statusMap['CLOSED'].includes(caseDetails.techlineStatusCode) && (
                            <section id={TechlineSections[TechlineSections.Resolution]}>
                                <Resolution caseDetails={caseDetails} />
                            </section>
                        )}
                    </div>
                )}
                {isCommentsExpanded ? renderExpandedRightContainer() : renderRightContainer()}
                {showCloseCaseModal && renderCloseCaseModal()}
            </div>
        </div>
    );
};
